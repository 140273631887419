import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import ClickBack from "../../../components/buttons/ClickBack";
import { useHistory } from "react-router-dom";
import {
  CreateFournisseurCommande,
  FetchAllLivreur,
  FetchListFournisseur,
} from "./DataService";
import { ErrorMessage, Field, Formik, useFormikContext } from "formik";
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import * as Yup from "yup";
import ModalValidation from "../../../components/modal/ModalValidation";
import PlacesAutocomplete from "react-places-autocomplete";
import AutoCalculateTTC from "../Component/AutoCalculateTTC";
import { REACT_APP_GOOGLE_MAPS_API_KEY } from "../../../utils/ApiConfig.js";

function AddCommandeFournisseur() {
  const [isGMapsLoaded, setIsGMapsLoaded] = useState(false);
  //Fonction pour la validation du formulaire d'ajout
  const validationSchema = Yup.object().shape({
    fournisseur: Yup.string().required("Fournisseur est requis"),
    numBonLivraison: Yup.string().required(
      "Numéro Bon de Livraison est requis"
    ),
    livraisonHT: Yup.string().required("Tarif de la livraison est requis"),
    commission: Yup.string().required("Commission est requise"),
    TVA: Yup.string().required("TVA est requis"),
    TTC: Yup.string().required("TTC est requis"),
    livreur: Yup.string().required("Livreur est requis"),
    telephone_client: Yup.string()
      .required("N° de Telephone Client est requis")
      .matches(
        /^0\d{9}$/,
        "Le numéro doit commencer par 0 et contenir 10 chiffres"
      ),
    NomClient: Yup.string().required("Nom Client est requis"),
    PrenomClient: Yup.string().required("Prènom Client est requis"),
    telFournisseurVal: Yup.string().required(
      "N° de Telephone Fournisseur est requis"
    ),
    RaisonSocialClient: Yup.string().required(
      "Raison Social Client est requis"
    ),
    date_livraisson: Yup.string().required("Date Livraisson est requis"),
    statut_paiement: Yup.string().required("Statut Paiement est requis"),
    statut_commande: Yup.string().required("Statut Commande est requis"),
    MontantCommande: Yup.number().required("Montant de la Commande est requis"),
    selectedFournisseurEmail: Yup.string()
      .email("Email invalide")
      .required("Email est requis"),
    // pickupAdr: Yup.string().required("Adresse Pick up est requise"),
    // address: Yup.string().required("Adresse de Livraison est requise"),
      // Vérification des adresses
  pickupAdr: Yup.string()
  .required("Adresse Pick up est requise")
  .test("is-valid-address", "L'adresse est incomplète. Veuillez fournir une adresse complète.", async (value) => {
    if (!value) return false;
    try {
      const results = await geocodeByAddress(value);
      if (results.length === 0) return false;
      
      const addressComponents = results[0]?.address_components;
      const hasStreetNumber = addressComponents.some(component => component?.types?.includes('street_number'));
      const hasStreet = addressComponents.some(component => component?.types?.includes('route'));
      const hasCity = addressComponents.some(component => component?.types?.includes('locality'));

      return hasStreetNumber && hasStreet && hasCity;
    } catch {
      return false;
    }
  }),

address: Yup.string()
  .required("Adresse de Livraison est requise")
  .test("is-valid-address", "L'adresse est incomplète. Veuillez fournir une adresse complète.", async (value) => {
    if (!value) return false;
    try {
      const results = await geocodeByAddress(value);
      if (results.length === 0) return false;
      
      const addressComponents = results[0]?.address_components;
      const hasStreetNumber = addressComponents.some(component => component?.types?.includes('street_number'));
      const hasStreet = addressComponents.some(component => component?.types?.includes('route'));
      const hasCity = addressComponents.some(component => component?.types?.includes('locality'));

      return hasStreetNumber && hasStreet && hasCity;
    } catch {
      return false;
    }
  }),
    file: Yup.mixed()
      .required("Un fichier PDF est requis")
      .test(
        "fileSize",
        "La taille du fichier ne doit pas dépasser 25 Mo",
        (value) => {
          return value && value.size <= 25 * 1024 * 1024; // 25 Mo en octets
        }
      )
      .test("fileType", "Seuls les fichiers PDF sont autorisés", (value) => {
        return value && value.type === "application/pdf";
      }),
  });
  const [showOkModal, setShowOkModal] = useState(false);
  const [selectedFournisseurEmail, setSelectedFournisseurEmail] = useState("");
  const [tarifLiv, setTarifLiv] = useState("");
  const [comissionVal, setComissionVal] = useState("");
  const [listFournisseurs, setListFournisseur] = useState([]);
  const [listLivreur, setListLivreur] = useState([]);
  const [deliveryLatLng, setDeliveryLatLng] = useState(null);
  const [pickupLatLng, setPickupLatLng] = useState(null);
  const [address, setAddress] = useState("");
  const [pickupAdr, setPickUpAdr] = useState("");
  const [telFournisseurVal, setTelFournisseurVal] = useState("");
  const history = useHistory();
  const [file, setFile] = useState(null);
  const currentDate = new Date().toISOString().slice(0, 16);

  const handleChangeFournisseur = (e, setFieldValue) => {
    const selectedFournisseurId = e.target.value;
    const selectedFournisseur = listFournisseurs.find(
      (fournisseur) => fournisseur.id == selectedFournisseurId
    );
    if (selectedFournisseur) {
      setSelectedFournisseurEmail(selectedFournisseur?.email);
      setFieldValue("selectedFournisseurEmail", selectedFournisseur?.email);
      setTarifLiv(selectedFournisseur?.user_profil?.montantLivraison);
      setFieldValue(
        "livraisonHT",
        selectedFournisseur?.user_profil?.montantLivraison
      );
      setComissionVal(selectedFournisseur?.user_profil?.commission);
      setFieldValue("commission", selectedFournisseur?.user_profil?.commission);
      setTelFournisseurVal(
        selectedFournisseur?.user_profil?.TelFournisseur || ""
      );
      setFieldValue(
        "telFournisseurVal",
        selectedFournisseur?.user_profil?.TelFournisseur
      );
    }
  };

  const HandleSave = async (values) => {
    const formData = new FormData();
    formData.append("id_fournisseur", values.fournisseur);
    formData.append("emailFournisseur", selectedFournisseurEmail);
    formData.append("TelFournisseur", telFournisseurVal);
    formData.append("NomClient", values.NomClient);
    formData.append("PrenomClient", values.PrenomClient);
    formData.append("RaisonSocialClient", values.RaisonSocialClient);
    formData.append("AdressePickUp", pickupAdr);
    formData.append("altitudeFournisseur", pickupLatLng.lat);
    formData.append("longitudeFournisseur", pickupLatLng.lng);
    formData.append("AdresseLivraison", address);
    formData.append("altitudeLivreur", deliveryLatLng.lat);
    formData.append("longitudeLivreur", deliveryLatLng.lng);
    formData.append("id_livreur", values.livreur);
    formData.append("Commentaire", values.Commentaire);
    formData.append("DateCommande", currentDate);
    formData.append("DateLivraison", values.date_livraisson);
    formData.append("TelClient", values.telephone_client);
    formData.append("StatuPaiement", values.statut_paiement);
    formData.append("StatuCommande", values.statut_commande);
    formData.append("NBonLivraison", values.numBonLivraison);
    formData.append("Bordereau", file);
    formData.append("MontantCommande", values.MontantCommande);
    formData.append("livraisonHT", values.livraisonHT);
    formData.append("commission", values.commission);
    formData.append("TVA", values.TVA);
    formData.append("TTC", values.TTC);
    try {
      const response = await CreateFournisseurCommande(formData);
      setShowOkModal(true);
      setTimeout(() => {
        history.push("/commandes_Allfournisseur");
      }, 1001); // 1001 millisecondes (2 secondes)
    } catch (error) {
      if (error.response && error.response.status === 422) {
      } else {
        console.error(
          "There was an error adding the fournisseur order!",
          error
        );
      }
    }
  };

  const handleChangeAddress = (address, type, setFieldValue) => {
    if (type === "pickup") {
      setFieldValue("pickupAdr", address);
      setPickUpAdr(address);
    } else {
      setFieldValue("address", address);
      setAddress(address);
    }
  };

  // const handleSelect = async (address, type, setFieldValue) => {
  //   try {
  //     const results = await geocodeByAddress(address);
  //     if (results.length > 0) {
  //       const { lat, lng } = await getLatLng(results[0]);
  //       if (type === "pickup") {
  //         setFieldValue("pickupAdr", address);
  //         setFieldValue("longitudeFournisseur", lng);
  //         setFieldValue("altitudeFournisseur", lat);
  //         setPickUpAdr(address);
  //         setPickupLatLng({ lat, lng });
  //       } else {
  //         setFieldValue("address", address);
  //         setAddress(address);
  //         setFieldValue("altitudeLivreur", lat);
  //         setFieldValue("longitudeLivreur", lng);
  //         setDeliveryLatLng({ lat, lng });
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
 
  const handleSelect = async (address, type, setFieldValue, setFieldError) => {
    try {
      setFieldError(type === "pickup" ? "pickupAdr" : "address", "");
        const results = await geocodeByAddress(address);

        if (results.length > 0) {
            const addressComponents = results[0]?.address_components;

            const hasStreetNumber = addressComponents.some(component => component?.types?.includes('street_number'));
            const hasStreet = addressComponents.some(component => component?.types?.includes('route'));
            const hasCity = addressComponents.some(component => component?.types?.includes('locality'));

            if (!hasStreetNumber || !hasStreet || !hasCity) {
              setFieldError(type === "pickup" ? "pickupAdr" : "address", "L'adresse est incomplète. Veuillez fournir une adresse complète.");
              return;
          }

            const { lat, lng } = await getLatLng(results[0]);

            if (type === "pickup") {
                setFieldValue("pickupAdr", address);
                setFieldValue("longitudeFournisseur", lng);
                setFieldValue("altitudeFournisseur", lat);
                setPickUpAdr(address);
                setPickupLatLng({ lat, lng });
            } else {
                setFieldValue("address", address);
                setAddress(address);
                setFieldValue("altitudeLivreur", lat);
                setFieldValue("longitudeLivreur", lng);
                setDeliveryLatLng({ lat, lng });
            }
        } else {
          setFieldError("Erreur : Aucun résultat trouvé pour l'adresse.");
        }
    } catch (error) {
        setFieldError(type === "pickup" ? "pickupAdr" : "address", "Erreur lors de la récupération de l'adresse.");
        console.error("Erreur:", error);
    }
};
 

  const searchOptions = {
    componentRestrictions: { country: ["fr","mc"] },
  types: ["geocode","establishment"],
  };

  //Cette fonction pour afficher liste des fournisseurs
  const fetchFournisseurData = async () => {
    try {
      const response = await FetchListFournisseur();
      setListFournisseur(response?.data);
    } catch (error) {
      console.error("Error lors de affiche liste fournisseur:", error);
    }
  };
  //Cette fonction pour afficher liste des livreurs
  const fetchLivreurData = async () => {
    try {
      const response = await FetchAllLivreur();
      setListLivreur(response.data);
    } catch (error) {
      console.error("Error lors de affiche liste livreur:", error);
    }
  };
  //Cette fonction pour fermer le Pop Up
  const handleOkModalClose = () => {
    setShowOkModal(false);
  };
  useEffect(() => {
    if (!window.google) {
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      gmapScriptEl.async = true;
      gmapScriptEl.defer = true;
      gmapScriptEl.onload = () => setIsGMapsLoaded(true);
      document.body.appendChild(gmapScriptEl);
    } else {
      setIsGMapsLoaded(true);
    }
  }, []);
  useEffect(() => {
    fetchFournisseurData();
    fetchLivreurData();
  }, []);
  return (
    <Container>
      <div>
        <p className="h6">Accueil / Commandes Founisseurs / Ajouter</p>
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack editPage={true} />
            <h1 className="p-1">Ajouter une Commande fournisseur</h1>
          </Col>
        </Row>
        <Formik
          initialValues={{
            selectedFournisseurEmail: "",
            fournisseur: "",
            telFournisseurVal: "",
            NomClient: "",
            PrenomClient: "",
            pickupAdr: "",
            altitude_Fournisseur: "",
            longitude_Fournisseur: "",
            address: "",
            altitude_Livreur: "",
            longitude_Livreur: "",
            livreur: "",
            date_livraisson: "",
            telephone_client: "",
            statut_paiement: "",
            statut_commande: 3,
            file: "",
            numBonLivraison: "",
            MontantCommande: "",
            livraisonHT: "",
            commission: "",
            TVA: 20,
            TTC: "",
            RaisonSocialClient: "",
            Commentaire: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            HandleSave(values);
            //   setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldError,
            validateField,
          }) => (
            <Form onSubmit={handleSubmit}>
              <AutoCalculateTTC />
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title className="pb-3">
                    Détails de la commande
                  </Card.Title>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="listFournisseur">
                        <Form.Label>Fournisseur</Form.Label>
                        <Form.Select
                          className="form-control"
                          value={values.fournisseur}
                          name="fournisseur"
                          onChange={(e) => {
                            handleChange(e);
                            handleChangeFournisseur(e, setFieldValue);
                          }}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.fournisseur && !!errors.fournisseur
                          }
                        >
                          <option value="" disabled hidden>
                            Sélectionnez un Fournisseur
                          </option>
                          {listFournisseurs?.length > 0 ? (
                            listFournisseurs.map((fournisseur) => (
                              <option
                                key={fournisseur.id}
                                value={fournisseur.id}
                              >
                                {fournisseur?.user_profil?.RaisonSocial}
                              </option>
                            ))
                          ) : (
                            <option disabled>
                              Aucun fournisseur disponible
                            </option>
                          )}
                        </Form.Select>
                        <ErrorMessage
                          name="fournisseur"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formEmail_fournisseur"
                      >
                        <Form.Label>Adresse mail fournisseur</Form.Label>
                        <Form.Control
                          className="form-control"
                          type="email"
                          placeholder="nom@exemple.com"
                          name="selectedFournisseurEmail"
                          value={
                            values?.email_fournisseur ||
                            selectedFournisseurEmail
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            setSelectedFournisseurEmail(value);
                            setFieldValue("selectedFournisseurEmail", value);
                          }}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.selectedFournisseurEmail &&
                            !!errors.selectedFournisseurEmail
                          }
                        />
                        <ErrorMessage
                          name="selectedFournisseurEmail"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formNom_telfournisseur"
                      >
                        <Form.Label>N° de téléphone Fournisseur </Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="telFournisseurVal"
                          placeholder="N° de téléphone"
                          value={values?.TelFournisseur || telFournisseurVal}
                          onChange={(e) => {
                            const value = e.target.value;
                            setTelFournisseurVal(value);
                            setFieldValue("TelFournisseur", value);
                          }}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.telFournisseurVal &&
                            !!errors.telFournisseurVal
                          }
                        />
                        <ErrorMessage
                          name="telFournisseurVal"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formNom_Nomclient"
                      >
                        <Form.Label>Nom Client </Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="NomClient"
                          placeholder="Nom Client"
                          value={values.NomClient}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.NomClient && !!errors.NomClient}
                        />
                        <ErrorMessage
                          name="NomClient"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formPrenom_client"
                      >
                        <Form.Label>Prénom Client </Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="PrenomClient"
                          placeholder="Prénom Client"
                          value={values.PrenomClient}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.PrenomClient && !!errors.PrenomClient
                          }
                        />
                        <ErrorMessage
                          name="PrenomClient"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formTelephone_client"
                      >
                        <Form.Label>N° de téléphone client </Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="telephone_client"
                          placeholder="Télephone Client"
                          value={values.telephone_client}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.telephone_client &&
                            !!errors.telephone_client
                          }
                        />
                        <ErrorMessage
                          name="telephone_client"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formRaisonSocial_client"
                      >
                        <Form.Label>Raison Social Client </Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="RaisonSocialClient"
                          placeholder="Raison Social Client"
                          value={values.RaisonSocialClient}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.RaisonSocialClient &&
                            !!errors.RaisonSocialClient
                          }
                        />
                        <ErrorMessage
                          name="RaisonSocialClient"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="listLivreur">
                        <Form.Label>Livreur</Form.Label>
                        <Form.Select
                          className="form-control"
                          value={values.livreur}
                          name="livreur"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.livreur && !!errors.livreur}
                        >
                          <option value="" disabled hidden>
                            Sélectionnez un livreur
                          </option>
                          {listLivreur?.map((livreur) => (
                            <option key={livreur.id} value={livreur.id}>
                              {livreur.nom} {livreur.prenom}
                            </option>
                          ))}
                        </Form.Select>
                        <ErrorMessage
                          name="livreur"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col>
                      <Form.Label>Date de la commande : </Form.Label>
                      <input
                        type="datetime-local" // Définition du type datetime-local
                        id="date_commande"
                        name="date_commande"
                        className="form-control"
                        value={values.date_commande}
                        min={currentDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.date_commande && !!errors.date_commande
                        }
                      />
                      <ErrorMessage
                        name="date_commande"
                        component="div"
                        className="text-danger"
                      />
                    </Col> */}
                    <Col>
                      <Form.Label>Heure de livraison souhaitée: </Form.Label>
                      <input
                        type="datetime-local" // Définition du type datetime-local
                        id="date_livraisson"
                        min={currentDate}
                        name="date_livraisson"
                        className="form-control"
                        value={values.date_livraisson}
                        placeholder=""
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.date_livraisson && !!errors.date_livraisson
                        }
                      />
                      <ErrorMessage
                        name="date_livraisson"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBordereau">
                        <Form.Label>Bordereau</Form.Label>
                        <Form.Control
                          className="form-control"
                          type="file"
                          name="file"
                          placeholder="Bordereau"
                          accept=".pdf"
                          // value={values.file}
                          onChange={(event) => {
                            const elemtFile = event.currentTarget.files[0];
                            setFile(elemtFile);
                            setFieldValue("file", elemtFile);
                          }}
                          onBlur={handleBlur}
                          isInvalid={touched.file && !!errors.file}
                        />
                        <ErrorMessage
                          name="file"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label>Statut de Commande</Form.Label>
                        <Form.Select
                          className="form-control"
                          type="text"
                          name="statut_commande"
                          placeholder="statut commande"
                          value={values.statut_commande}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.statut_commande && !!errors.statut_commande
                          }
                        >
                          <option>Sélectionnez le Statut de Commande</option>
                          <option value="1">En cours de livraison</option>
                          <option value="2">Livré</option>
                          <option value="3">En cours de préparation</option>
                          <option value="6">Annulé</option>
                        </Form.Select>
                        <ErrorMessage
                          name="statut_commande"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formStatutPaiement"
                      >
                        <Form.Label>Statut de paiement</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className="form-control"
                          name="statut_paiement"
                          placeholder="Statut paiement"
                          value={values.statut_paiement}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.statut_paiement && !!errors.statut_paiement
                          }
                        >
                          <option>Sélectionnez le Statut de paiement</option>
                          <option value="1">Facturé</option>
                          <option value="2">Non Facturé</option>
                        </Form.Select>
                        <ErrorMessage
                          name="statut_paiement"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {isGMapsLoaded && (
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formAddresspickup"
                        >
                          <Form.Label>Adresse Pick up</Form.Label>
                          <PlacesAutocomplete
                            name="pickupAdr"
                            value={pickupAdr}
                            searchOptions={searchOptions}
                            onChange={(addr) =>
                              handleChangeAddress(addr, "pickup", setFieldValue)
                            }
                            onSelect={(addr) =>
                              handleSelect(addr, "pickup", setFieldValue, setFieldError)
                            }
                            onBlur={handleBlur}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div className="position-relative">
                                <input
                                  {...getInputProps({
                                    placeholder: "Adresse Pick Up",
                                    className: `form-control mb-3 ${
                                      Error !== "" ? "danger-input" : ""
                                    }`,
                                  })}
                                  id="floatingPickupAdr"
                                />

                                <div
                                  className={`container-autocomplete ${
                                    suggestions.length > 0
                                      ? "d-block"
                                      : "d-none"
                                  }`}
                                >
                                  {loading ? <div>Loading...</div> : null}
                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#41B6E6"
                                        : "#fff",
                                    };
                                    return (
                                      <div
                                        className="mapItem"
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <ErrorMessage
                            name="pickupAdr"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formAddress">
                          <Form.Label>Adresse Livraison</Form.Label>
                          <Autocomplete
                            name="address"
                            value={address}
                            searchOptions={searchOptions}
                            onChange={(addr) =>
                              handleChangeAddress(addr, "liv", setFieldValue)
                            }
                            onSelect={(addr) =>
                              handleSelect(addr, "liv", setFieldValue, setFieldError)
                            }
                            onBlur={handleBlur}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div className="mb-3">
                                <input
                                  {...getInputProps({
                                    placeholder: "Adresse de Livraison",
                                    className: `form-control mb-3 ${
                                      Error !== "" ? "danger-input" : ""
                                    }`,
                                  })}
                                  id="floatingAdr"
                                />
                                <div
                                  className={`container-autocomplete ${
                                    suggestions.length > 0
                                      ? "d-block"
                                      : "d-none"
                                  }`}
                                >
                                  {loading ? <div>Loading...</div> : null}
                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#41b6e6"
                                        : "#fff",
                                    };
                                    return (
                                      <div
                                        className="mapItem"
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </Autocomplete>
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBondelivraison"
                      >
                        <Form.Label>Numéro Bon de livraison</Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="numBonLivraison"
                          placeholder="Numéro Bon de livraison"
                          value={values.numBonLivraison}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.numBonLivraison && !!errors.numBonLivraison
                          }
                        />
                        <ErrorMessage
                          name="numBonLivraison"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formCommentaire">
                        <Form.Label>Commentaire</Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          name="Commentaire"
                          placeholder="Commentaire"
                          value={values.Commentaire}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.Commentaire && !!errors.Commentaire
                          }
                        />
                        <ErrorMessage
                          name="Commentaire"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {/* Montants */}
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title className="pb-3">Montants :</Card.Title>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formTarifLivraison"
                      >
                        <Form.Label>Tarif de Livraison</Form.Label>
                        <Field
                          type="number"
                          name="livraisonHT"
                          placeholder="Tarif de Livraison"
                          className="form-control"
                          value={values?.livraisonHT || tarifLiv}
                          onChange={(e) => {
                            const value = e.target.value;
                            setTarifLiv(value);
                            setFieldValue("livraisonHT", value);
                          }}
                        />
                        <ErrorMessage
                          name="livraisonHT"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formCommission">
                        <Form.Label>Commission %</Form.Label>
                        <Field
                          type="number"
                          name="commission"
                          placeholder="Commission %"
                          className="form-control"
                          value={values?.commission || comissionVal}
                          onChange={(e) => {
                            const value = e.target.value;
                            setComissionVal(value);
                            setFieldValue("commission", value);
                          }}
                        />
                        <ErrorMessage
                          name="commission"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formMontantCommande"
                      >
                        <Form.Label>Montant de la commande</Form.Label>
                        <Form.Control
                          className="form-control"
                          type="number"
                          step="0.01"
                          name="MontantCommande"
                          placeholder="Montant de la commande"
                          // value={values.MontantCommande}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.MontantCommande && !!errors.MontantCommande
                          }
                        />
                        <ErrorMessage
                          name="MontantCommande"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formtva">
                        <Form.Label>TVA</Form.Label>
                        <Field
                          type="number"
                          name="TVA"
                          placeholder="TVA"
                          className="form-control"
                          disabled="true"
                        />
                        <ErrorMessage
                          name="TVA"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <Form.Group className="mb-3" controlId="formMontantTTC">
                        <Form.Label>Montant TTC</Form.Label>
                        <Field
                          type="number"
                          name="TTC"
                          placeholder="Montant TTC"
                          className="form-control"
                          disabled="true"
                        />
                        <ErrorMessage
                          name="TTC"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Row>
                <Col>
                  <Form.Group
                    className="mt-3 d-flex justify-content-end align-items-center"
                    controlId="formSubmit"
                  >
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Enregistrer la commande
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
      <ModalValidation
        show={showOkModal}
        onClose={handleOkModalClose}
        onConfirm={handleOkModalClose}
        text={"Votre commande a été enregistrée et envoyer avec succès"}
      />
    </Container>
  );
}

export default AddCommandeFournisseur;
